import { Form } from '~/common/components/Form/Form';
import { useForm } from 'react-hook-form';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Block } from '~/common/components/Block/Block';
import { Button } from '~/common/components/Button/Button';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { useEffect, useState } from 'react';
import { AsyncSelect } from '~/common/components/Select/AsyncSelect';
import { Select } from '~/common/components/Select/Select';
import {
  // experienceOptions,
  getDatasetsOptions,
  googleColabOptions,
  internetQualityOptions,
  preferBeingTAForOptions,
  lowfiTAOptions,
  // programProficiencyOptions,
  yesOrNoOptions,
  // yesOrNo,
  yearsOfCourseResearchOptions,
  howDidYouHearAboutUsOptions,
  howDidYouHearAboutUsOptionsLabels,
  contactForFutureOptions,
  priorNMAExperienceOptions,
  priorNMAExperienceOptionsLabels,
  primaryExpertiseOptions,
} from '~/modules/applications/models/application.model';
import {
  timeSlotsOptions,
  timeSlots,
  timeSlotsLabels,
} from '~/modules/user/models/user.model';
import { Link } from '~/common/components/Link/Link';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { Table } from '~/common/components/Table/Table';
import { RadioButton } from '~/common/components/RadioButton/RadioButton';
import { Input } from '~/common/components/Input/Input';
import {
  useCreateTAApplicationRequest,
  useGetCourseDataSets,
  useLoadApplicationsRequest,
  useUpdateTAApplicationRequest,
} from '~/modules/applications/models/application.hooks';
import { useLoadCountriesRequest, useOnError, useSnackbar } from '~/modules/app/models/app.hooks';
import dayjs from 'dayjs';
import { history } from '~/app/history/history';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { userRoutes } from '~/modules/user/router/Router';
import { useDispatch } from 'react-redux';
import {
  createLogistic,
  updateLogistic,
} from '~/modules/user/redux/user.actions';
import { useUser } from '~/modules/user/models/user.hooks';
import { getTASeniority } from './utils';
import { scrollToTop, scrollToFirstError } from '~/modules/app/models/app.model';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const formNames = {
  how_did_you_hear_about_us: 'how_did_you_hear_about_us',
  maillist_or_other: 'maillist_or_other',
  contact_for_future: 'contact_for_future',
  prior_NMA_experience: 'prior_NMA_experience',
  has_bachelors_degree: 'has_bachelors_degree',
  participated_in_nma_last_year: 'participated_in_nma_last_year',
  // participated_as_ta: 'participated_as_ta',
  residing_country_during_course: 'residing_country_during_course',
  has_access_google_colab: 'has_access_google_colab',
  internet_works_fine: 'internet_works_fine',
  preprints_or_peer_reviewed_publications: 'preprints_or_peer_reviewed_publications',
  primary_expertise: 'primary_expertise',
  about_educational_background: 'about_educational_background',
  agree_to_abide_code_of_conduct: 'agree_to_abide_code_of_conduct',
  agree_terms_and_conditions: 'agree_terms_and_conditions',
  years_of_course_work_research: 'years_of_course_work_research',
  comfortable_data_set: 'comfortable_data_set',
  alternative_data_set: 'alternative_data_set',
  answers: 'answers',
  applied_as_lead_ta: 'applied_as_lead_ta',
  // being_pre_pod_test_materials: 'being_pre_pod_test_materials',
  prefer_being_ta_for: 'prefer_being_ta_for',
  open_to_lowfi: 'open_to_lowfi',
  // supervisor_email: 'supervisor_email',
  // TODO: change this in the front and backend to reflect question change
  month_ta_count: 'month_ta_count',
  video_link: 'video_link',
  agree_aforementioned_forty_hours: 'agree_aforementioned_forty_hours',
  possible_time_slots: 'possible_time_slots',
};

export const CreateTAApplicationForm = ({ defaultValues, isUpdate = false, previousPage, course, applicationId }) => {
  const { register, handleSubmit, setError, trigger, setValue, errors, watch } = useForm({ defaultValues });
  const setErrors = setErrorsFunction(setError);
  const datasets = useGetCourseDataSets(course.id);
  const datasetsOptions = getDatasetsOptions(datasets);
  const createTAApplication = useCreateTAApplicationRequest();
  const updateTAApplication = useUpdateTAApplicationRequest();
  const { setSnackbar } = useSnackbar();
  const loadApplications = useLoadApplicationsRequest();
  const dispatch = useDispatch();
  // load user for seniority calcs
  const user = useUser();

  // question related constants
  const questions = course.questions;
  const programmingQuestions = questions.filter(question => question.category === 'course_spec_prog');
  const domainQuestions = questions.filter(question => question.category === 'course_spec_dom');
  const taDomainQuestions = questions.filter(question => question.category === 'technical_dom');
  const taAnswerChoices = taDomainQuestions[0]?.answer_choices;

  // answers array to accumulate answers and send them back to api
  const answers = questions.map(question => ({ question: question.id, answer_choice: '1' }));
  const dropdownQuestions = questions.filter(question => question.category !== 'technical_dom')
    .map(question => ({ id: question.id }));
  const tableQuestions = questions.filter(question =>  question.category === 'technical_dom')
    .map(question => ({ id: question.id }));

  const [dropdownErrors, setDropdownErrors] = useState({});
  const [tableErrors, setTableErrors] = useState({});
  const [timeSlotErrors, setTimeSlotErrors] = useState({});

  // Validation and Error handling for dropdrown questions
  const validateDropdownAnswers = data => {
    const unasweredQuestions = dropdownQuestions.filter(
      question => (typeof data[`answers_questionID_${question.id}`] === 'undefined')
    )
    if (unasweredQuestions.length) {
      unasweredQuestions.forEach(question => {
        setDropdownErrors(errors => ({
          ...errors,
          [`answers_questionID_${question.id}`]: 'This field is required.'
        }))
      })
      return false;
    } else {
      return true;
    }
  };

  const checkDropdownErrors = () => {
    const updatedErrors = {};
    Object.entries(dropdownErrors).forEach(([key, error]) => {
       if (typeof watch()[key] === 'undefined') {
        updatedErrors[key] = error;
      }
    });
    setDropdownErrors(updatedErrors);
  };

  useEffect(() => {
    scrollToFirstError(dropdownErrors);
  }, [dropdownErrors]);

  // Validation and error handling for table questions
  const validateTableAnswers = data => {
    const unansweredTableQuestions = tableQuestions.filter(
      question => (data[`answers_questionID_${question.id}`] === null)
    );
    if (unansweredTableQuestions.length) {
      unansweredTableQuestions.forEach(question => {
        setTableErrors(errors => ({
          ...errors,
          [`answers_questionID_${question.id}`]: 'This field is required.'
        }))
      });
      // control submit flow
      return false;
    } else {
      // Parse tableAnswers and replace values with answer ids
      getTableAnswersIds(data);
      return true
    }
  };

  const checkTableErrors = () => {
    const updatedErrors = {};
    Object.entries(tableErrors).forEach(([key, error]) => {
       if (watch()[key] === null) {
        updatedErrors[key] = error;
      }
    });
    setTableErrors(updatedErrors);
  };

  const getTableAnswersIds = (data) => {
    tableQuestions.forEach(question => {
      const choiceVal = data[`answers_questionID_${question.id}`];
      const choice = taAnswerChoices.find(choice => choice.answer_text === choiceVal)
      data[`answers_questionID_${question.id}`] = choice.id;
    })
  }

  useEffect(() => {
    scrollToFirstError(tableErrors);
  }, [tableErrors]);

  const validateProjectTATimeSlots = (data) => {
    if (
      data.prefer_being_ta_for === 'project_TA' ||
      data.prefer_being_ta_for === 'no_preference'
    ) {
      if (
        data.possible_time_slots.length < 2
      ) {
        setTimeSlotErrors(errors => (
          {
            ...errors,
            possible_time_slots: 'Please select 2 timeslots or change preference above to regular TA.'
          }
        ));
        return false;
      }
      return true
    } else {
      data.possible_time_slots = [];
      return true;
    }
  }

  const checkTimeSlotErrors = () => {
    if (watch().prefer_being_ta_for === 'regular_TA') {
      setTimeSlotErrors({});
    } else if (watch().possible_time_slots?.length >= 2) {
      setTimeSlotErrors({});
    }
  };

  useEffect(() => {
    scrollToFirstError(timeSlotErrors);
  }, [timeSlotErrors]);

  useEffect(() => {
    register({ name: formNames.how_did_you_hear_about_us });
    register({ name: formNames.contact_for_future });
    register({ name: formNames.prior_NMA_experience });
    register({ name: formNames.primary_expertise });
    register({ name: formNames.residing_country_during_course });
    register({ name: formNames.has_access_google_colab });
    register({ name: formNames.internet_works_fine });
    register({ name: formNames.years_of_course_work_research });
    register({ name: formNames.comfortable_data_set });
    register({ name: formNames.alternative_data_set });
    register({ name: formNames.applied_as_lead_ta });
    // register({ name: formNames.being_pre_pod_test_materials });
    register({ name: formNames.prefer_being_ta_for });
    register({ name: formNames.open_to_lowfi });
    register({ name: formNames.possible_time_slots });
  }, [register]);

  const setValueWithTrigger = (name, value) => {
    setValue(name, value);
    trigger(name);
  };

  const parseAnswers = data => {
    answers.forEach(answer => {
      answer.answer_choice = data[`answers_questionID_${answer.question}`];
    })
    data.answers = answers;
  };

  // handles default values for dropdown questions on update
  const defaultAnswers = question => {
    const prevAnswers = defaultValues?.answers || [];
    const defaultAnswer = prevAnswers.find(answer => answer.question === question.id);
    if (!defaultAnswer || typeof defaultAnswer.answer_choice === 'string') {
      return null;
    } else {
      const defaultChoice = question.answer_choices.find(choice => choice.id === defaultAnswer.answer_choice);
      if (defaultChoice) {
        defaultValues[`answers_questionID_${question.id}`] = defaultChoice.id;
        return defaultChoice.answer_text;
      } else {
        return null
      }
    }
  };

  const sortAnswers = (a, b) => {
    if (a.value > b.value) {
      return 1;
    } else if (a.value < b.value) {
      return -1;
    } else {
      return 0;
    }
  };

  const renderSkillQuestions = (questions) => {
    return (
      questions.map((question) => {
            const inputFormName = formNames.answers + `_questionID_${question.id}`;
            const options = question.answer_choices.map(choice => ({
              value: choice.rating,
              label: choice.answer_text,
              id: choice.id,
            })).sort(sortAnswers);
            return (
              <Form.Row>
                <Select
                  name={inputFormName}
                  onChange={option => {
                    setValueWithTrigger(inputFormName, option.id);
                    checkDropdownErrors();
                  }}
                  options={options}
                  label={question.question_text}
                  error={dropdownErrors[inputFormName]}
                  defaultValue={defaultAnswers(question)}
                  innerRef={register(
                    { name: inputFormName },
                  )}
                />
              </Form.Row>
            );
      })
    );
  };

  const onError = useOnError(setErrors);

  const getAvailablePreference = () =>{ 
    for (let i=1; i<=6; i++) {
      let application_key = `preferred_application_${i}`
      if (user.logistic[application_key] === null) {
        return application_key;
      }
    }
      return 'preferred_application_6'
  }
   // TODO: Allow null and validate on frontend
   const validateOtherFields = data => {
    const placeholderText = '';
    if (!isMaillistOrOther) {
      data.maillist_or_other = placeholderText;
    }
  }


  const onSubmit = data => {
    if (!validateProjectTATimeSlots(data)) {
      setSnackbar('Please select atleast 2 timeslots')
      return;
    }
    if (!validateDropdownAnswers(data)) {
      setSnackbar('Required fields missing')
      return;
    }
    if (!validateTableAnswers(data)) {
      setSnackbar('Table fields missing')
      return;
    };
    validateOtherFields(data);
    // set seniority in payload
    let seniority = getTASeniority(user);
    if (seniority === undefined) {
      seniority = 'undefined';
    }
    data['seniority'] = seniority;
    // If answers are validated, accumulate all answers into an object for db consumption
    parseAnswers(data);
    if (isUpdate) {
      updateTAApplication(applicationId, data)
        .then(() => {
          setSnackbar('Application is updated');
          loadApplications();
          // history.push(applicationsRoutes.homepage);
          history.push(userRoutes.applications);
        })
        .catch(onError);
    } else {
      createTAApplication(course.id, data)
        .then(() => {
          setSnackbar('Application is submitted');
          // history.push(applicationsRoutes.homepage);
          let preference_key = getAvailablePreference();
          const logisticValues = { ...user.logistic,  [preference_key] : course.name + " ta" }; 
          dispatch(updateLogistic(logisticValues, logisticValues.id))
            .then(() => {
              history.push(userRoutes.applications);
            })
            .catch(onError);
          history.push(userRoutes.applications);
        })
        .catch(onError);
    }
  };

  const loadCountriesOptions = useLoadCountriesRequest();

  // Table rendering and error display

  // Function that return list of radio button for table entry
  const fillDataCallback = row => {
    // This handle default table entries on update
    const questionId = Number(row.formName.slice(-1));
    const prevAnswer = defaultValues?.answers.find(answer => answer.question === questionId) || null;
    const answerVal = prevAnswer ? taAnswerChoices.find(choice => choice.id === prevAnswer.answer_choice).answer_text : '';
    return [
      row.label,
      <RadioButton onChange={checkTableErrors} defaultChecked={answerVal === '1' ? '1' : ''} innerRef={register} name={row.formName} value="1" />,
      <RadioButton onChange={checkTableErrors} defaultChecked={answerVal === '2' ? '2' : ''} innerRef={register} name={row.formName} value="2" />,
      <RadioButton onChange={checkTableErrors} defaultChecked={answerVal === '3' ? '3' : ''} innerRef={register} name={row.formName} value="3" />,
      <RadioButton onChange={checkTableErrors} defaultChecked={answerVal === '4' ? '4' : ''} innerRef={register} name={row.formName} value="4" />,
      <RadioButton onChange={checkTableErrors} defaultChecked={answerVal === '5' ? '5' : ''} innerRef={register} name={row.formName} value="5" />,
    ];
  };

  const taDomainQuestionsFillData = taDomainQuestions.map(
    question => ({label: question.question_text, formName: `answers_questionID_${question.id}`})
  );

  const taDomainQuestionsTableRows = taDomainQuestionsFillData.map(fillDataCallback);

  const domainTableErrors = {};

  // create an object to hold onto errors by row index
  Object.entries(tableErrors).forEach(([key, value]) => {
    const domainTableErrorsIndex = taDomainQuestionsFillData.findIndex(row => row.formName === key);
    if (domainTableErrorsIndex > -1) {
      domainTableErrors[domainTableErrorsIndex] = value;
    }
  });
  const howDidYouHearAboutUs = watch("how_did_you_hear_about_us"); 
  const isMaillistOrOther = howDidYouHearAboutUs?.includes(howDidYouHearAboutUsOptions.maillist) || 
                            howDidYouHearAboutUs?.includes(howDidYouHearAboutUsOptions.other);


  const startDate = dayjs(course.deadlines?.start_date);
  const endDate = dayjs(course.deadlines?.end_date);
  // const startDate = dayjs(course.deadlines?.start_date);

  // Why is this not used?
  // const taApplicationEndDate = dayjs(course.deadlines?.ta_application_end_date).utc().format('MMMM DD, YYYY HH:mm');

  const formattedStartDate = startDate.utc().format('MMMM DD, YYYY');
  const formattedEndDate = endDate.utc().format('MMMM DD, YYYY');
  // const formattedStartDate = startDate.format('MMMM, YYYY');
  // const formattedApplicationStartDate = studentApplicationStartDate.format('MMMM, YYYY');

  // const beingLeadTa = watch(formNames.applied_as_lead_ta);
  const deadlines = course.deadlines;
  // console.log(deadlines);
  // const taTrainingStartTime = beingLeadTa ? deadlines.lead_ta_training_start_date : deadlines.ta_training_start_date;
  // console.log(taTrainingStartTime);
  const daysOfTraining = 1;
  const formattedNotLeadStartTime = dayjs(deadlines.ta_training_start_date).utc().format('MMMM DD');
  const formattedLeadStartTime = dayjs(deadlines.lead_ta_training_start_date).utc().format('MMMM DD');
  const formattedNotLeadEndTime = dayjs(deadlines.ta_training_start_date).add(daysOfTraining, 'day').utc().format('MMMM DD');
  const formattedLeadEndTime = dayjs(deadlines.lead_ta_training_start_date).add(daysOfTraining, 'day').utc().format('MMMM DD');

  // const formattedTaTrainingStartTime = dayjs(taTrainingStartTime).utc().format('MMMM DD');

  const prePodStartTime = course?.deadlines.prepod_start_date;
  const prePodEndTime = course?.deadlines.prepod_end_date;

  const formattedPrePodStartTime = dayjs(prePodStartTime).utc().format('MMMM DD');
  const formattedPrePodEndTime = dayjs(prePodEndTime).utc().format('MMMM DD, YYYY');

  const prefer_ta = watch().prefer_being_ta_for;

  return (
    <Block title={'Teaching Assistant (TA) Application - ' + course.name} className="create-student-application-form">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Checkbox
            name={formNames.has_bachelors_degree}
            innerRef={register({ required: true })}
            text={
              <>
                I have a bachelor's degree. This is required to apply to be a TA.
              </>
            }
            error={errors[formNames.has_bachelors_degree]?.type}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>How did you hear about Climatematch or Neuromatch? Select all that apply.</Form.Label>
          {Object.entries(howDidYouHearAboutUsOptions).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={formNames.how_did_you_hear_about_us}
              value={value}
              defaultValue={formNames.how_did_you_hear_about_us}
              text={howDidYouHearAboutUsOptionsLabels[value]}
              key={key}
            />
          ))}
          <div className="error">{errors?.how_did_you_hear_about_us?.message}</div>
        </Form.Row>
        {isMaillistOrOther && (
          <Form.Row>
            <Input
              label="Please specify how you heard about Climatematch or Neuromatch: "
              name={formNames.maillist_or_other}
              placeholder="Specify here..." 
              innerRef={register}
              error={errors?.maillist_or_other?.message}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Select
            name={formNames.contact_for_future}
            onChange={option => {
              setValueWithTrigger(formNames.contact_for_future, option?.value ?? option);
            }}
            options={contactForFutureOptions}
            label={
              <>
                Would you be interested in being contacted in the future to help
                spread the word about new courses among your community?
              </>
            }
            error={errors.contact_for_future?.message}
            defaultValue={defaultValues?.contact_for_future}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>Did you participate in any previous Academies? (Please check all that apply)</Form.Label>
          {Object.entries(priorNMAExperienceOptions).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={formNames.prior_NMA_experience}
              value={value}
              defaultValue={formNames.prior_NMA_experience}
              text={priorNMAExperienceOptionsLabels[value]}
              key={key}
            />
          ))}
          <div className="error">{errors?.prior_NMA_experience?.message}</div>
        </Form.Row>

        {/*<Form.Row>*/}
        {/*  <Checkbox*/}
        {/*    name={formNames.participated_in_nma_last_year}*/}
        {/*    innerRef={register}*/}
        {/*    text="I participated in the NMA last year"*/}
        {/*    error={errors.participated_in_nma_last_year?.message}*/}
        {/*  />*/}
        {/*</Form.Row>*/}

        <Form.Row>
          <AsyncSelect
            name={formNames.residing_country_during_course}
            label={<>What country/region will you be living in during the course? ({endDate.utc().format('MMMM YYYY')})?</>}
            onChange={option => {
              setValueWithTrigger(formNames.residing_country_during_course, option?.value ?? option);
            }}
            loadOptions={loadCountriesOptions}
            //isCreatable
            error={errors.residing_country_during_course?.message}
            defaultValue={defaultValues?.residing_country_during_course}
          />
        </Form.Row>
        <Form.Row>
          <Select
            name={formNames.has_access_google_colab}
            onChange={option => {
              setValueWithTrigger(formNames.has_access_google_colab, option?.value ?? option);
            }}
            options={googleColabOptions}
            label={
              <>
                Will you have access to Google Colab (
                <Link to="https://colab.research.google.com/" isCrossDomain newTab>
                  colab.research.google.com
                </Link>
                ) during the course? We will try to find alternative accommodations if not.
              </>
            }
            error={errors.has_access_google_colab?.message}
            defaultValue={defaultValues?.has_access_google_colab}
          />
        </Form.Row>

        <Form.Row>
          <Select
            name={formNames.internet_works_fine}
            onChange={option => {
              setValueWithTrigger(formNames.internet_works_fine, option?.value ?? option);
            }}
            options={internetQualityOptions}
            label="Will you have access to a stable internet connection during the course?"
            error={errors.internet_works_fine?.message}
            defaultValue={defaultValues?.internet_works_fine}
          />
        </Form.Row>
        <Form.Row>
          <Select
            label="Please select your primary domain of expertise. This question is for demographics purposes and will not affect your application for the course. "
            name={formNames.primary_expertise}
            options={primaryExpertiseOptions}
            onChange={option => {
              setValueWithTrigger(formNames.primary_expertise, option?.value ?? option);
            }}
            defaultValue={defaultValues?.primary_expertise}
            error={errors[formNames.primary_expertise]?.message}
          />
        </Form.Row>

        {renderSkillQuestions(programmingQuestions)}

        {renderSkillQuestions(domainQuestions)}

        <Form.Row>
          <Form.Label>
            What is your level of comfort talking about these {course.name} topics? (1 = have no experience, 3 = some course work/job experience, 5 = extensive experience for 4+ years)
          </Form.Label>
          <Table
            headCells={['Topic', 1, 2, 3, 4, 5]}
            rows={taDomainQuestionsTableRows}
            errors={domainTableErrors}
          />
        </Form.Row>

        <Form.Row>
          <Textarea
            name={formNames.preprints_or_peer_reviewed_publications}
            innerRef={register}
            label="Do you have any preprints or peer-viewed publications in which you were a substantial author? If so, please paste titles and links here:"
            error={errors.preprints_or_peer_reviewed_publications?.message}
          />
        </Form.Row>

        <Form.Row>
          <Select
            options={yearsOfCourseResearchOptions}
            name={formNames.years_of_course_work_research}
            label={`How many years of course work and/or research have you done in ${course.name}?`}
            onChange={option => {
              setValueWithTrigger(formNames.years_of_course_work_research, option?.value ?? option);
            }}
            error={errors.years_of_course_work_research?.message}
            defaultValue={defaultValues?.years_of_course_work_research}
          />
        </Form.Row>

        <Form.Row>
          <Select
            label={
              <>
                Are you interested in being a lead TA? Lead TAs have additional teaching experience and guide small groups of
                either regular or project TAs based on your role. There is an additional ~1-2h training session for lead TAs.
              </>
            }
            name={formNames.applied_as_lead_ta}
            options={yesOrNoOptions}
            onChange={option => {
              setValueWithTrigger(formNames.applied_as_lead_ta, option?.value ?? option);
            }}
            error={errors.applied_as_lead_ta?.message}
            defaultValue={defaultValues?.applied_as_lead_ta}
          />
        </Form.Row>

        {/* <Form.Row>
          <Select
            label={
              <>
                Are you interested in being a part of the pre-pod to test materials? Pre-pods run from{' '}
                {formattedPrePodStartTime} - {formattedPrePodEndTime}. Pre-pods are where new course material is tested and improved before the course. Testers with different backgrounds give feedback to the content creators and editors to facilitate learning in the actual course.
              </>
            }
            name={formNames.being_pre_pod_test_materials}
            options={yesOrNoOptions}
            onChange={option => {
              setValueWithTrigger(formNames.being_pre_pod_test_materials, option?.value ?? option);
            }}
            error={errors.being_pre_pod_test_materials?.message}
            defaultValue={defaultValues?.being_pre_pod_test_materials}
          />
        </Form.Row> */}

        <Form.Row>
          <Select
            label={'Would you prefer being a regular TA, a project TA, or either?' +
                ' If you select a specific role, you will only be considered for that role. There are fewer project TA positions than regular TA positions.'
            }
            name={formNames.prefer_being_ta_for}
            options={preferBeingTAForOptions}
            onChange={option => {
              setValueWithTrigger(formNames.prefer_being_ta_for, option?.value ?? option);
              checkTimeSlotErrors();
            }}
            error={errors.prefer_being_ta_for?.message}
            defaultValue={defaultValues?.prefer_being_ta_for}
          />
        </Form.Row>
        {prefer_ta !== 'regular_TA' && prefer_ta !== undefined && (
          <div>
            <Form.Row>
              <Form.Label>
                <p>Project teaching assistants are required to be available for two projects times (but are not required to be available during the synchronous coursework time). <b>In case you are selected as a project teaching assistant</b>, please indicate which possible project time slots you are available for. Please note you will not be selected as a project teaching assistant unless you can attend at least <b>two</b> time slots.</p>
                <p>You can use the widget below to convert to your local time but please only pay attention to the project times for this question.</p>
              </Form.Label>

             <iframe title="timeslot-widget"
                      src="https://neuromatchacademy.github.io/widgets/tz.html"
                      frameborder="0"
                      width="100%"
                      height="550"
                      style={{background: "transparent", border: "1px solid rbg(204,204,204)", borderRadius: "1%"}}
              ></iframe>

              <Form.Label className="preferences">
                Please select all project time slots that could potentially work for you if you are selected as a project TA.
              </Form.Label>
              <Form.Row className="logistics-info__radio-btns">
                {Object.keys(timeSlots).map(ts => (
                  <Checkbox
                    className="logistics-info__checkbox"
                    innerRef={register}
                    name={formNames.possible_time_slots}
                    value={ts}
                    text={timeSlotsLabels[ts]}
                    key={ts}
                    onChange={checkTimeSlotErrors}
                    defaultChecked={defaultValues?.possible_time_slots.includes(ts)}
                    disabled={false}
                  />
                ))}
              </Form.Row>
              <div className='timeslot-error'>{timeSlotErrors?.possible_time_slots}</div>
            </Form.Row>
          </div>
        )}
        <Form.Row>
          <Select
            label="We are still looking at ways to support students with unreliable internet. Would you be open to acting as a TA for these students, which means the same number of hours but answering questions mostly via discord instead of being on zoom the whole time. Please note there is no guarantee of this option!"
            name={formNames.open_to_lowfi}
            options={lowfiTAOptions}
            onChange={option => {
              setValueWithTrigger(formNames.open_to_lowfi, option?.value ?? option);
            }}
            error={errors.open_to_lowfi?.message}
            defaultValue={defaultValues?.open_to_lowfi}
          />
        </Form.Row>

        <Form.Row>
          <Select
            options={datasetsOptions}
            name={formNames.comfortable_data_set}
            label="Which of these types of projects would you prefer to mentor as a regular or project TA? Pods are pre-assigned to project topics. We will try to match you to students based on your preference below and this will be impossible to change later."
            onChange={option => {
              setValueWithTrigger(formNames.comfortable_data_set, option?.value ?? option);
            }}
            error={errors.comfortable_data_set?.message}
            defaultValue={defaultValues?.comfortable_data_set}
          />
        </Form.Row>

        <Form.Row>
          <Select
            options={datasetsOptions}
            name={formNames.alternative_data_set}
            label="What is your second preference for your group project?"
            onChange={option => {
              setValueWithTrigger(formNames.alternative_data_set, option?.value ?? option);
            }}
            error={errors.alternative_data_set?.message}
            defaultValue={defaultValues?.alternative_data_set}
          />
        </Form.Row>

        {/* <Form.Row>
          <Input
            name={formNames.supervisor_email}
            innerRef={register}
            label={
              <>
                What is your supervisor's email? This is the SAME email address you sent the{' '}
                <Link to="https://airtable.com/shroR57sfy4z4WBss" isCrossDomain newTab>
                  approval form
                </Link>{' '}
                to. If you do not have a supervisor please briefly tell us why.
              </>
            }
            error={errors.supervisor_email?.message}
          />
        </Form.Row> */}

        <Form.Row>
          <Input
            name={formNames.month_ta_count}
            innerRef={register}
            label={<>How many different courses have you TA'd or taught?</>}
            type="number"
            min={0}
            step={1}
            error={errors.month_ta_count?.message}
          />
        </Form.Row>

        <Form.Row>
          <Input
            name={formNames.video_link}
            innerRef={register}
            label={
              <>
                Please create a video following the instructions{' '}
                <Link to="https://github.com/NeuromatchAcademy/TA-applications" isCrossDomain newTab>
                  here
                </Link>{' '}
                and provide a publicly accessible link to your application video here (include the full url; i.e https://example.com):
              </>
            }
            error={errors.video_link?.message}
          />
        </Form.Row>
        <Form.Row>
          <Textarea
            name={formNames.about_educational_background}
            innerRef={register}
            label="Why do you want to be part of this course a TA? Briefly tell us about your educational background, research interests, motivation, and expected benefit of attending (50-300 words)."
            error={errors.about_educational_background?.message}
          />
        </Form.Row>

        <Form.Row>
          <Checkbox
            name={formNames.agree_aforementioned_forty_hours}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I commit to the aforementioned ~40 hours per week from {formattedStartDate} -{' '}
                {formattedEndDate} in addition to 2 days of training ({formattedNotLeadStartTime} - {formattedNotLeadEndTime})
              </>
            }
            error={errors[formNames.agree_aforementioned_forty_hours]?.type}
          />
        </Form.Row>

        <Form.Row>
          <Checkbox
            name={formNames.agree_to_abide_code_of_conduct}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I agree to abide by the{' '}
                <Link
                  to="https://github.com/NeuromatchAcademy/precourse/blob/master/CODE_OF_CONDUCT.md"
                  isCrossDomain
                  newTab
                >
                  Code of Conduct
                </Link>
              </>
            }
            error={errors[formNames.agree_to_abide_code_of_conduct]?.type}
          />
        </Form.Row>

        <Form.Row>
          <Checkbox
            name={formNames.agree_terms_and_conditions}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I have read and agree to the{' '}
                <Link
                  to='https://docs.neuromatch.io/p/kpUb79-ES8l_Gj/Privacy-Policy'
                  isCrossDomain
                  newTab
                >
                  terms and conditions
                </Link>
                {' '}in the Neuromatch Privacy Policy, summarized as:
                <ul>
                  <li>Your data will be shared with select Neuromatch staff to manage the program </li>
                  <li>Your anonymized data may be shared with funding agencies</li>
                  <li>We may contact you by email to update you on your application</li>
                </ul>
              </>
            }
            error={errors[formNames.agree_terms_and_conditions]?.type}
          />
        </Form.Row>
        <Form.Row>This submission is final so please review your answers to ensure they are correct!</Form.Row>
        <Form.Row className="create-student-application-form__buttons">
          {isUpdate ? (
            <>
              <Button type="submit">Update application</Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  previousPage();
                  scrollToTop();
                }}
              >
                Previous Page
              </Button>
              <Button type="submit">Submit application</Button>
            </>
          )}
        </Form.Row>
      </Form>
    </Block>
  );
};
