import { CoursesBlocks } from '~/modules/applications/pages/Home/blocks/components/CoursesBlocks/CoursesBlocks';
import { ApplicationsBlock } from '~/modules/applications/pages/Home/blocks/components/ApplicationsBlock/ApplicationsBlock';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { Link } from '~/common/components/Link/Link';
import { Button } from '~/common/components/Button/Button';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Logo from '~/common/assets/img/header/logo-black.png';

export const Home = () => {
  const history = useHistory();
  const handleClick = () => {history.push('/applications')}

  const [generalInfo, setGeneralInfo] = useState('');
  const [courseInfo, setCourseInfo] = useState('');
  const [prospectiveStudents, setProspectiveStudents] = useState('');
  const [prospectiveTA, setProspectiveTA] = useState('');

  useEffect(() => {
      const loadFiles = async () => {
          try {
              const generalModule = await import(`./content/general_info.md`);
              const generalResponse = await fetch(generalModule.default);
              const generalText = await generalResponse.text();
              setGeneralInfo(generalText);

              const courseModule = await import(`./content/course_info.md`);
              const courseResponse = await fetch(courseModule.default);
              const courseText = await courseResponse.text();
              setCourseInfo(courseText);

              const prospectiveStudentsModule = await import(`./content/prospective_students.md`);
              const prospectiveStudentsResponse = await fetch(prospectiveStudentsModule.default);
              const prospectiveStudentsText = await prospectiveStudentsResponse.text();
              setProspectiveStudents(prospectiveStudentsText);

              const prospectiveTAModule = await import(`./content/prospective_ta.md`);
              const prospectiveTAResponse = await fetch(prospectiveTAModule.default);
              const prospectiveTAText = await prospectiveTAResponse.text();
              setProspectiveTA(prospectiveTAText);

          } catch (err) {
              console.error('Error loading markdown files:', err);
          }
      };
      loadFiles();
  }, []);

  return (
    <div className="home narrow mt-20">
      <img className='dual-logo' src={ Logo } alt='logo'/>
      <Collapse title="General Information" defaultExpanded={false}>
          <Markdown>{generalInfo}</Markdown>
      </Collapse>

      <Collapse title="Course Information" defaultExpanded={false}>
          <Markdown>{"##### **Current course offerings**"}</Markdown>
          <CoursesBlocks withButtons={false} />
          <Markdown>{courseInfo}</Markdown>
      </Collapse>

      <Collapse title="Information for prospective students" defaultExpanded={false}>
          <Markdown>{prospectiveStudents}</Markdown>
      </Collapse>

      <Collapse title="Information for prospective teaching assistans" defaultExpanded={false}>
          <Markdown>{prospectiveTA}</Markdown>
      </Collapse>

      <Button onClick={handleClick} className='apply-btn'>Apply Now</Button>
    </div>
  );
};
