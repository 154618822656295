import { flattenObj } from '~/common/libs/FormHelpers/FormHelpers';

export const getUrlSearchParams = search => {
  const _search = search || window.location.search?.substring(1);
  if (!_search) {
    return {};
  }
  return JSON.parse('{"' + decodeURI(_search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
};

export const tokenTypes = {
  email: 'email',
  password: 'password',
  new_email: 'new_email',
};

export const noValueCodes = {
  null: 'null',
  blank: 'blank',
  required: 'required',
  empty: 'empty',
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const scrollToFirstError = errors => {
  try {
    const flattenedErrors = flattenObj(errors);
    scrollToElementByName(Object.keys(flattenedErrors)[0]);
  } catch (e) {
    console.error(e);
  }
};

export const scrollToElementByName = name => {
  const element = document.querySelectorAll(`[name="${name}"]`)[0];
  if (element) {
    element.parentElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
};

export function getNumberWithOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
