import { Block } from '~/common/components/Block/Block';
import { Form } from '~/common/components/Form/Form';
import { useForm } from 'react-hook-form';
import { Button } from '~/common/components/Button/Button';
import { useGetStudentApplication } from '~/modules/applications/models/application.hooks';
import { baselinePrice, setBaselinePrice, calculateAcademicDiscount, calculateCOLAAdjustment, calculateProcessingFee, calculatePayment } from '~/modules/applications/models/application.model';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
dayjs.extend(utc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const StudentPay = ({ application, previousPage, nextPage, waiveFee }) => {
  const { handleSubmit } = useForm();
  const studentApplication = useGetStudentApplication(application.student_application)?.data;
  // const course_name = application.course_name;
  const course_price = application.course_price;
  // Set baseline price as course price 
  setBaselinePrice(course_price);

  const dispatch = useDispatch();

  const isFunded = true

  const isAcademia = studentApplication && !!(studentApplication.is_industry ? 0 : 1)
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const residingCountry = studentApplication && studentApplication.residing_country_during_course
  const processingFee = studentApplication && parseFloat(calculateProcessingFee(studentApplication.cola))

  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment + processingFee).toFixed(2);
  // Stripe expects input to be in cents, ideally as an interger.
  // So we enforced this for price and recommendedPayment
  const price = Math.floor(recommendedPayment * 100);
  const isPriceZero = price <= 0 ;

  const onSubmit = data => {
    const locationUrl = window.location.href;
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <>
      <Block title="Enrollment: Payment" className="student-payment">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
          <Form.Row>
            At Neuromatch, our mission is to democratize education and research in the computational sciences globally. We’re thrilled to have you join us in our mission and we hope we can help you use computational approaches to drive real-world impact. 
            <br></br> <br></br>
            To make our courses accessible globally, we use a regional fee adjustment model based on GDP per capita data from the World Bank. This ensures participants from diverse socioeconomic and geographical backgrounds can access our courses, making high-quality education truly global. 
            <br></br> <br></br>
            Course fees are vital for sustaining our programs, and your fees directly support our dedicated Teaching Assistants (TAs) who spend an entire month teaching you. Without this support, Neuromatch could not continue its mission. Course fees are vital for sustaining our programs, and your fees directly support our dedicated Teaching Assistants (TAs) who spend an entire month teaching you. <i>Without this support, Neuromatch could not continue its mission.</i>
          </Form.Row>

          <Form.Row>
            Based on your profile information, including your country of residence during the course, we have calculated this adjusted course fee. 
          </Form.Row>
          <Form.Row className="student-application-status__discount-checklist">
            <Form.Row>&#10004; Apply my institution discount</Form.Row>
            <Form.Row>&#10004; Apply my cost of living adjustment</Form.Row>
          </Form.Row>
          <br></br>
          <Form.Row className="student-application-status__discount-table">
            <center>
            <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
              <tbody>
              <tr>
                <td>Baseline Price</td>
                <td className="alignright">  $ {baselinePrice}</td>
              </tr>
              {isAcademia && (<tr>
                <td>Academic Discount</td>
                <td className="alignright">- $ {academicDiscount}</td>
              </tr>)}
              <tr>
                <td>Cost of Living Adjustment ({residingCountry})</td>
                <td className="alignright">- $ {colaAdjustment}</td>
              </tr>
              <tr>
                <td>Non-Refundable Processing Fee</td>
                <td className="alignright">  $ {processingFee}</td>
              </tr>
              <tr className="total">
                <td className="alignright" width="80%"><b>Your Total</b></td>
                <td className="alignright"><b>$ {recommendedPayment} (USD)</b></td>
              </tr>
              </tbody>
            </table>
              </center>
            </Form.Row>
          { !isPriceZero && (<div>
          <Form.Row className="student-application-status__pay-button">
            <Button
              type="submit"
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row>
          <Form.Row className="student-application-status__buttons">
            <Button className="student-application-status__buttons" onClick={() => {nextPage(); scrollToTop()}}>
              I am self-funding and cannot afford this price
            </Button>
          </Form.Row>
          </div>)}
          {isPriceZero && (<Form.Row className="student-application-status__pay-button">
            <Button className="student-application-status__pay-button" onClick={() => {waiveFee(); scrollToTop()}}>
              Waive fee and enroll
            </Button>
          </Form.Row>)}
        </Form>
      </Block>
    </>
  );
};
