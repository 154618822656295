import { useSelector } from 'react-redux';
import { selectCourses } from '~/modules/applications/redux/applications.selectors';
import { CourseBlock } from '~/modules/applications/pages/Home/blocks/components/CourseBlock/CourseBlock';
import React, { createRef, useRef, useState, useEffect } from "react";
import { Button } from '~/common/components/Button/Button';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { Link } from '~/common/components/Link/Link';
import { useUser } from '~/modules/user/models/user.hooks';
import { selectApplications } from '~/modules/applications/redux/applications.selectors';
import dayjs from 'dayjs';

export const Checklist = () => {
  const applications = useSelector(selectApplications);
  const courses = useSelector(selectCourses);
  const lastCourseAdded = courses[courses.length - 1];
  const startDate = dayjs(lastCourseAdded?.deadlines.ta_application_start_date);
  const user = useUser();

  const checkStep1 = () => {
    if (user.demographic && user.general_info) {
      const modifiedDate = dayjs(user.general_info.modified);
      // check that the modified date is after the lastest course startdate
      return modifiedDate > startDate;
    } else {
      return false;
    }
  }

  const checkStep2 = () => {
    if (user.logistic) {
      // TODO: do the same from step one
      return true;
    } else {
      return false;
    }
  }

  const checkStep3 = () => {
    let appSent = false;
    applications.forEach(application => {
      if (application.status === 'applied') {
        appSent = true;
      }
    });
    return appSent;
  }

  const checkStep4 = () => {
    if (user.logistic) {
      if (user.logistic.preferred_application_1) {
        return true;
      }
    }
    return false;
  }

  // const checkStep5 = () => {
  //   let paid = false;
  //   applications.forEach(application => {
  //     if (application.status === 'paid') {
  //       paid = true;
  //     }
  //   });
  //   return paid;
  // };

  return (
    <div className="application-instructions">
      <h1>Application instructions</h1>
      <div className="steps">
        <p><b>Step 1: </b>Fill out/update your profile <Link to="/account">here</Link>.</p>
        <Checkbox checked={checkStep1()}></Checkbox>
      </div>
      <div className="steps">
        <p><b>Step 2: </b>Fill out your logistics information <Link to="/logistics">here</Link>.</p>
        <Checkbox checked={checkStep2()}></Checkbox>
      </div>
      <div >
      </div>
      <div className="steps">
        <p><b>Step 3: </b>Read the description of the course you're interested in below and then <i> apply as student or teaching assistant using the buttons following the text below</i>. Your application will be sent immediately to our reviewing team. We are not yet able to accommodate saving your progress so we recommend preparing your answers elsewhere and then copying them in </p>
        <Checkbox className="check-button" checked={checkStep3()}></Checkbox>
      </div>
      <div className="steps">
      <p><b>Step 4: </b>Repeat step 3 for other applications you want to submit. You can submit up to three applications. If you make 2 or more applications, select your preferences <Link to="/applications">here</Link>.</p>
        <Checkbox checked={checkStep4()}></Checkbox>
      </div>
      {/*<div className="steps">
      <p><b><font color='#bd49d7'>Step 5: </font></b>If your application is marked as Accepted, click on the course name in order to pay</p>
        <Checkbox checked={checkStep5()}></Checkbox>
      </div>*/}
    </div>
  );
};
