import { Button } from '~/common/components/Button/Button';
import { Block } from '~/common/components/Block/Block';
import { Input } from '~/common/components/Input/Input';
import { Form } from '~/common/components/Form/Form';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { useLoadApplicationsRequest, useWaiveFeeRequest } from '~/modules/applications/models/application.hooks';
import { useForm } from 'react-hook-form';
import { useOnError } from '~/modules/app/models/app.hooks';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { baselinePrice, setBaselinePrice, calculateProcessingFee, calculateAcademicDiscount, calculateCOLAAdjustment, calculateSelfFundedDiscount, calculateAffordabilityDiscount, calculatePayment } from '~/modules/applications/models/application.model';
import { useGetStudentApplication } from '~/modules/applications/models/application.hooks';
import { loadStripe } from '@stripe/stripe-js';
import { useRef, useState, useEffect } from "react";

const customFeeFormName = 'custom_fee';
const reasonsFormName = 'waive_fee_reasons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const WaiveFeeModal = ({application, previousPage}) => {

  const course_price = application.course_price;
  // Set baseline price as course price 
  setBaselinePrice(course_price);

  const loadApplications = useLoadApplicationsRequest();
  const waiveFee = useWaiveFeeRequest();
  const { register, handleSubmit, watch, setError, errors } = useForm();
  const setErrors = setErrorsFunction(setError);
  const onError = useOnError(setErrors);
  const dispatch = useDispatch();

  // const customFee = watch(customFeeFormName);

  const studentApplication = useGetStudentApplication(application.student_application)?.data;

  const isFunded = false
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const selfFundedDiscount = studentApplication && calculateSelfFundedDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const affordabilityDiscount = studentApplication && calculateAffordabilityDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const processingFee = studentApplication && parseFloat(calculateProcessingFee(studentApplication.cola))
  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount - affordabilityDiscount + processingFee).toFixed(2)

  const [customFee, setCustomFee] = useState(null);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    setCustomFee((recommendedPayment - processingFee).toFixed(2));
    setPrice(recommendedPayment);
  }, [recommendedPayment, processingFee]);

  const isPriceZero = Math.floor(recommendedPayment * 100) <= 0;
  const waiveReason = isPriceZero ? '$0 USD Course Fee - cannot legally pay' : ''
  const formRef = useRef();

  const handleFee = value => {
    const amount = parseFloat(value);
    if (amount) {
      setCustomFee(amount);
      setPrice((amount + processingFee).toFixed(2));
    } else {
      setCustomFee(0);
      setPrice((processingFee).toFixed(2));
    }
  }

  const onWaive = data => {
    waiveFee(application.student_application, data[reasonsFormName])
      .then(() => {
        loadApplications();
      })
      .catch(onError);
  };

  const onPay = e => {
    e.preventDefault();
    const locationUrl = window.location.href;
    const totalPrice = Math.floor(price * 100);
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: totalPrice,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
     <Block className="student-application-status" title="Enrollment: Waive Fee">
        <Form ref={formRef}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
          {!isPriceZero &&<div>
          <Form.Row>
            Neuromatch’s goal is to be affordable to everybody, but our course fees are essential to sustaining our programs. Without them Neuromatch would not be able to operate.
              <br></br><br></br>
              Your recommended payment after a cost of living adjustment, a self-funded discount, and an additional financial hardship discount is <b>${recommendedPayment} USD</b>, but you can decide on a cost you can afford. 
          </Form.Row>
          <Form.Row>
             Enter the custom fee you'd like to pay (in USD):{' '}
                  <Input type="number"
                         min={2}
                         value={customFee}
                         onChange={(e) => handleFee(e.target.value)}
                         // name={customFeeFormName}
                         placeholder={recommendedPayment}
                         innerRef={register}
                         step={1} />{' '}
          </Form.Row>
          <Form.Row>
            Our non-refundable processing fee (also subject to regional fee adjustments) is required to cover administrative costs and cannot be waived. This is $50.00 USD (or equivalent)
          </Form.Row>
          <Form.Row className="student-application-status__discount-table">
            <center>
              <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
                <tbody>
                <tr>
                  <td>Custom Fee</td>
                  <td className="alignright">  $ {customFee}</td>
                </tr>
                <tr>
                  <td>Non-Refundable Processing Fee</td>
                  <td className="alignright">  $ {processingFee}</td>
                </tr>
                <tr className="total">
                  <td className="alignright" width="80%"><b>Your Total</b></td>
                  <td className="alignright"><b>$ {price} (USD)</b></td>
                </tr>
                </tbody>
              </table>
            </center>
          </Form.Row>
          <Form.Row className="student-application-status__pay-button">
            <Button type="submit" onClick={onPay}
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row> </div>}
        {isPriceZero && (
          <> 
          <Form.Row>
            If you want to support Neuromatch, there are many other ways you can help us on our mission to increase access to science.
            <br></br> <br></br>
            Please consider one of the following:
             <ul>
              <li>Share your excitement for our course on social media </li>
              <li>Share the course with colleagues or classmates </li>
              <li>Volunteer at Neuromatch </li>
              <li>Suggest colleagues or classmates to volunteer </li>
              <li>Ask your school or institution about providing credit for this course and helping us give more credit around the world </li>
            </ul>

              Please, explain the situation that prevents you from being able to pay using the field below.

          </Form.Row>
          <Form.Row className="waive-fee__footer">
            <div className="waive-fee__textarea-wrapper">
              <Textarea
                placeholder="...reasons..."
                defaultValue={waiveReason}
                rows={3}
                className="waive-fee__textarea"
                name={reasonsFormName}
                innerRef={register({ required: "Required field",
                    minLength: {
                        value:   200 * !isPriceZero,
                        message: "Mininimum length is 200 characters"
                 }})}
                error={errors[reasonsFormName]?.message}
              />
            </div>
            </Form.Row>
            <Form.Row className="student-application-status__buttons">
              <Button className="student-application-status__buttons" onClick={handleSubmit(onWaive)}>
                Enroll without paying
              </Button>
          </Form.Row>
          </>
        )}
        </Form>
    </Block>
  );
};
