import { Form } from '~/common/components/Form/Form';
import { Block } from '~/common/components/Block/Block';
import { Button } from '~/common/components/Button/Button';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Link } from '~/common/components/Link/Link';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useGetCourse, useLoadApplicationsRequest } from '~/modules/applications/models/application.hooks';
import { useWishToEnrollRequest } from '~/modules/user/models/user.hooks';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
dayjs.extend(utc);

const formNames = {
    airtable_form : 'airtable_form ',
}
export const StudentSupplementalEnrollment = ({ application, previousPage, nextPage}) => {
  const { register, errors, handleSubmit } = useForm();
  const course = useGetCourse(application.course);
  const wishToEnrollRequest = useWishToEnrollRequest();
  const loadApplications = useLoadApplicationsRequest();

  if (!course) {
    return null;
  }

  const onSubmit = () => {
    wishToEnrollRequest(application.student_application)
      .then(() => {loadApplications()
                   nextPage()
                   scrollToTop()
      })
      .catch(e => {
        console.error(e);
      });
  };
  return (
    <Block className="student-application-status" title="Enrollment: Supplemental Form">
      <Form className="student-application-status__info" onSubmit={handleSubmit(onSubmit)}>
        <div className="student-application-status__text">
          <Form.Row>
            <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
              Go Back
            </Button>
          </Form.Row>
          <Form.Row>
            Please fill out the supplemental course enrollment form in Airtable. <b>Don't forget to come back here to finish your enrollment - it will not be finalized until after the payment step!</b>
          </Form.Row>
          <Form.Row>
            The following link will take you first to your NMA portal and then redirect you to an Airtable form - please be patient as it can take a minute. If you aren't already signed into the portal, you will have to first sign-in for the redirect to work
          </Form.Row>
          <Form.Row>
            <Link
              to={course.enrollment_form_link}
              isCrossDomain
              newTab
            >
              Take me to supplemental course enrollment form
            </Link>
          </Form.Row>
          <Form.Row>
            <Checkbox
              name={formNames.airtable_form}
              innerRef={register({ required: "Required field"})}
              text="I have filled out the airtable form"
              error={errors[formNames.airtable_form]?.message}
            />
          </Form.Row>
          <Form.Row className="student-application-status__buttons">
            <Button className="student-application-status__buttons"  type="submit">
              Continue with enrollment
            </Button>
            </Form.Row>
        </div>
      </Form>
    </Block>
  );
};
