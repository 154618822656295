import { Form } from '~/common/components/Form/Form';
import { Select } from '~/common/components/Select/Select';
import { useForm } from 'react-hook-form';
import { Button } from '~/common/components/Button/Button';
import { useEffect, useState } from 'react';
import { Input } from '~/common/components/Input/Input';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { AsyncSelect } from '~/common/components/Select/AsyncSelect';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { Tooltip } from '~/common/components/Tooltip/Tooltip';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { userRoutes } from '~/modules/user/router/Router';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectApplications } from '~/modules/applications/redux/applications.selectors';
import {
  statusesOptions,
  heldPositionTimesOptions,
  timeSlots,
  timeSlotsLabels,
  timeSlotsOptions,
  languages,
  languagesOptions,
  languagesLabels,
  statuses,
  contestedAreas,
  gendersOptions,
  races,
  racesLabels,
  parentsCompletedCollegeOptions,
  socioeconomicSituationsOptions,
  genders,
  genderLabels,
  dependantsOptions,
  disabilitiesOptions,
  disabilities,
} from '~/modules/user/models/user.model';
import { useUser } from '~/modules/user/models/user.hooks';
import { useDispatch } from 'react-redux';
import {
  createGeneralInfo,
  createLogistic,
  createDemographic,
  searchInstitutes,
  update,
  updateGeneralInfo,
  updateLogistic,
  updateDemographic
} from '~/modules/user/redux/user.actions';
import { Link } from '~/common/components/Link/Link';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { loadCourses } from '~/modules/applications/redux/applications.actions';
import { useLoadCountriesRequest, useOnError, useSnackbar, useRef} from '~/modules/app/models/app.hooks';
import { noValueCodes, scrollToFirstError } from '~/modules/app/models/app.model';

import { applicationStatuses, applicationStatusesLabels, yesOrNo } from '~/modules/applications/models/application.model';

const logisticsFormNames = {
  preferred_application_1: 'preferred_application_1',
  preferred_application_2: 'preferred_application_2',
  preferred_application_3: 'preferred_application_3',
  preferred_application_4: 'preferred_application_4',
  preferred_application_5: 'preferred_application_5',
  preferred_application_6: 'preferred_application_6',
};
// TODO: (konstantine) remove logic for 6 prefs, consider max apps
const applicationPreferencesForm = [
  { label :  'Preference 1' ,  name : logisticsFormNames.preferred_application_1 } ,
  { label :  'Preference 2' ,  name : logisticsFormNames.preferred_application_2 } ,
  { label :  'Preference 3' ,  name : logisticsFormNames.preferred_application_3 } ,
  { label :  'Preference 4' ,  name : logisticsFormNames.preferred_application_4 } ,
  { label :  'Preference 5' ,  name : logisticsFormNames.preferred_application_5 } ,
  { label :  'Preference 6' ,  name : logisticsFormNames.preferred_application_6 } ,
]

Object.entries(logisticsFormNames).forEach(([key, value]) => {
  logisticsFormNames[key] = 'logistic.' + value;
});

export const Preferences = ({ courses }) => {
  const history = useHistory();
  const user = useUser();
  const defaultValues = { ...user };
  const { register, handleSubmit, setValue, errors, setError, trigger, watch } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const setErrors = setErrorsFunction(setError);
  const { setSnackbar } = useSnackbar();
  const onError = useOnError(setErrors);

  // Course statuses are established during model serialization, considering the course deadlines
  // Logic checks whether all course application deadlines have ended
  const applications_status = courses.flatMap((course) => { return [course.student_application_status, course.ta_application_status]}) 
  let countApplicationsClosed = applications_status.filter(status => status === applicationStatuses.deadline_ended || status === applicationStatuses.enrolled_deadline_ended).length
  const isDeadLineEnded = applications_status.length === countApplicationsClosed

  const allApplications = useSelector(selectApplications);
  const activeCourseIds = courses.filter(course => (course.is_active)).map(course => (course.id));
  // these are current applications
  const applications = allApplications.filter(apl => (activeCourseIds.includes(apl.course)));

  const applicationOptions = applications.flatMap((apl) => {
    return { label : apl.course_name + ' ' + apl.application_type , value : apl.course_name + ' ' + apl.application_type }
  })

  const updatePreferences = (data) => {
    const temp_data = defaultValues.logistic;
    Object.entries(temp_data).forEach(([key, value]) => {
      if (key.slice(0, 13) !== "preferred_app") {
        data.logistic[key] = value
      }
    });
  }
  const [prefErrors, setPrefErrors] = useState({});

  const getPrefValues = () => {
    const prefValues = [];
    applications.forEach(app => {
      let value = app.course_name;
      if (app.ta_application) {
        value += " ta"
      } else if (app.student_application) {
        value += " student"
      }
      prefValues.push(value);
    })
    return prefValues
  }
  const validatePreferences = (data) => {
    const prefValues = getPrefValues();
    const prefSet = new Set();
    const numPrefs = applications.length;
    let hasErrors = false;
    for (let i=1; i<=numPrefs; i++) {
      let pref = data.logistic[`preferred_application_${i}`]
      if (pref === null) {
        setPrefErrors(errors => {
          return (
            {
              ...errors,
              [`preferred_application_${i}`]: 'Please fill out your preference'
            }
          );
        })
        hasErrors = true;
        setSnackbar('Missing preferences');
      } else if (!prefValues.includes(pref)) {
        setPrefErrors(errors => {
          return (
            {
              ...errors,
              [`preferred_application_${i}`]: 'Please choose your preference for current appilcations'
            }
          );
        })
        hasErrors = true;
        setSnackbar('Misconfigured preferences');
      } else if (prefSet.has(pref)) {
        setPrefErrors(errors => {
          return (
            {
              ...errors,
              [`preferred_application_${i}`]: 'Preferences must be unique'
            }
          );
        })
        hasErrors = true;
        setSnackbar('Duplicate preferences');
      }
      // Add pref to set
      prefSet.add(pref)
    }
    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  }

  const checkPreferences = () => {
    const updatedErrors = {};
    const prefs = watch().logistic;
    Object.entries(prefErrors).forEach((key, value) => {
      if (prefs[key] === null) {
        updatedErrors[key] = value;
      }
    });
    setPrefErrors(updatedErrors);
  }

  useEffect(() => {
    scrollToFirstError(prefErrors);
  }, [prefErrors]);

  let isExpanded = true;
  const prefs = watch().logistic;
  for (let i=1; i<=applications.length; i++) {
    if (prefs[`preferred_application_${i}`] === null) {
      isExpanded = true;
    }
  }

  const onSubmit = data => {
    if (!validatePreferences(data)) {
      return;
    }
    updatePreferences(data);
    if (!user.logistic) {
      const requests = [];

      if (!user.logistic) {
        const logistics = { ...data.logistic, user: user.id };
        requests.push(dispatch(createLogistic(logistics)));
      } else {
        requests.push(dispatch(updateLogistic(data.logistic, user.logistic.id)));
      }

      // prefixes for setting errors to form
      const prefixesForErrors = ['logistic.'];

      let isSuccessUpdate = true;

      Promise.allSettled(requests).then(results => {
        // update courses
        dispatch(loadCourses());
        let allErrors = {};
        results.forEach((response, index) => {
          if (response.status === 'rejected') {
            const errors = response?.reason?.response?.data;
            isSuccessUpdate = false;
            if (errors) {
              const errorsWithPrefix = {};
              Object.entries(errors).forEach(([key, value]) => {
                errorsWithPrefix[prefixesForErrors[index] + key] = value;
              });
              allErrors = { ...allErrors, ...errorsWithPrefix };
              setErrors(errorsWithPrefix);
            }
          }
        });
        if (isSuccessUpdate) {
          // history.push(applicationsRoutes.homepage);
          history.push(userRoutes.applications);
          setSnackbar('Preferences updated');
        } else {
          scrollToFirstError(allErrors);
          const missingField = Object.values(allErrors).find(([value]) => {
            return value?.code === noValueCodes.null || value?.code === noValueCodes.blank;
          });
          if (missingField) {
            setSnackbar('Required fields missing');
          } else {
            setSnackbar('An error appeared');
          }
        }
      });
    } else {
      //history.push(applicationsRoutes.homepage);
      // dispatch(updateLogistic(data))
      dispatch(updateLogistic(data.logistic, user.logistic.id))
        .then(() => {
          // history.push(applicationsRoutes.homepage);
          history.push(userRoutes.applications);
          setSnackbar('Preferences updated');
        })
        .catch(onError);
    }
  };

  const setValueWithTrigger = (name, value) => {
    setValue(name, value);
    trigger(name);
  };

  // registering selects
  useEffect(() => {
    // register({ name: logisticsFormNames.preferred_time_slot });
    // register({ name: logisticsFormNames.second_preferred_time_slot });
    // register({ name: logisticsFormNames.preferred_language });
    // register({ name: logisticsFormNames.comfort_english });
    register({ name: logisticsFormNames.preferred_application_1 });
    register({ name: logisticsFormNames.preferred_application_2 });
    register({ name: logisticsFormNames.preferred_application_3 });
    register({ name: logisticsFormNames.preferred_application_4 });
    register({ name: logisticsFormNames.preferred_application_5 });
    register({ name: logisticsFormNames.preferred_application_6 });
  }, [register]);

  return (
    <div className="logistics-info">
      <Form className="logistics-info__form" onSubmit={handleSubmit(onSubmit)}>
      { applications.length > 1 && (
        <Collapse title="Preferences" defaultExpanded={isExpanded}>
          <Markdown>
          {
                  "`PLEASE NOTE THAT YOU CAN CHANGE THIS UNTIL THE APPLICATION DEADLINE, BUT IT WILL BE FINAL AFTER.` " +
                  "  \n" +
                  "  \n" +
                  "Please list the applications you will submit in order of preference. " +
                  "You will not be able to enroll in a lower preference if accepted to a higher preference as they take place at the same time."
          }
          </Markdown>

          {applicationPreferencesForm.map((course, index) => (
            <Form.Row className="preferences">
              {isDeadLineEnded && (
              <Tooltip  place="left">{applicationStatusesLabels.deadline_ended}</Tooltip>
              )}
              {/* index  >= applicationOptions.length && (
              // NOTE: not_applied is no longer used
              <Tooltip  place="left">{applicationStatusesLabels.not_applied}</Tooltip>
              )*/}
              {  index  < applicationOptions.length && (<Select
                label={
                  course.label + ":"
                }
                // isClearable
                name={course.name}
                defaultValue={user?.logistic?.[course.name] }
                options={applicationOptions}
                onChange={option => {
                  setValueWithTrigger(logisticsFormNames[course.name], option?.value ?? option);
                    checkPreferences();
                }}
                error={errors?.logistic?.[course.name]?.message || prefErrors?.[course.name]}
                isDisabled={isDeadLineEnded | index  >= applicationOptions.length }
              /> )}
            </Form.Row>
              )
          )}
        </Collapse>
        )}
        { applications.length > 1 && (
          <Form.Row>
            <Button type="submit" style={{marginTop: '200px'}} onClick={() => trigger()} disabled={isDeadLineEnded}>
             Submit Preferences
            </Button>
          </Form.Row>
        )}
      </Form>
    </div>
  );
};
