import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearApplications,
  clearCourses,
  createMentorApplication,
  createOrUpdateCertificate,
  createOrUpdateAttendance,
  createStudentApplication,
  createTAApplication,
  getDataSets,
  getMentorApplication,
  getPod,
  getStudentApplication,
  getTAApplication,
  loadApplications,
  loadCourses,
  updateMentorApplication,
  updateStudentApplication,
  updateTAApplication,
  selfFunded,
  hardship,
  waiveFee,
  widthdrawMentorApplication,
  widthdrawStudentApplication,
  widthdrawTAApplication,
  createTAOnboarding
} from '~/modules/applications/redux/applications.actions';
import {
  selectApplications,
  selectApplicationsModel,
  selectCourses,
  selectDatasets,
} from '~/modules/applications/redux/applications.selectors';
import { useUser } from '~/modules/user/models/user.hooks';
import { useCanRequest } from '~/modules/app/models/app.hooks';
import { getUrlSearchParams } from '~/modules/app/models/app.model';
import { applicationRequestStatuses, applicationTypes } from '~/modules/applications/models/application.model';
import { history } from '~/app/history/history';
import { useParams } from 'react-router-dom';
import { applicationsRoutes } from '~/modules/applications/router/Router';

export const useSelfFundedRequest = () => {
  const dispatch = useDispatch();
  return useCallback((id, reason) => dispatch(selfFunded(id, reason)), [dispatch]);
};

export const useHardshipRequest = () => {
  const dispatch = useDispatch();
  return useCallback((id, reason) => dispatch(hardship(id, reason)), [dispatch]);
};

export const useWaiveFeeRequest = () => {
  const dispatch = useDispatch();
  return useCallback((id, reason) => dispatch(waiveFee(id, reason)), [dispatch]);
};

export const useLoadCoursesRequest = () => {
  const dispatch = useDispatch();
  return useCallback((page = 1, pageSize = 100) => dispatch(loadCourses(page, pageSize)), [dispatch]);
};

export const useLoadCourses = () => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const user = useUser();
  const canRequest = useCanRequest();
  const loadCourses = useLoadCoursesRequest();

  useEffect(() => {
    if (!fetched && user && canRequest) {
      loadCourses().finally(() => setFetched(true));
    }
    if (!user && fetched) {
      setFetched(false);
      dispatch(clearCourses());
    }
  }, [dispatch, fetched, user, canRequest]);
};

export const useLoadApplicationsRequest = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(loadApplications()), [dispatch]);
};

export const useLoadApplications = () => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const canRequest = useCanRequest();
  const user = useUser();
  const loadApplications = useLoadApplicationsRequest();

  useEffect(() => {
    if (!fetched && canRequest && user) {
      loadApplications().finally(() => setFetched(true));
    }
    if (fetched && !user) {
      setFetched(false);
      dispatch(clearApplications());
    }
  }, [dispatch, canRequest, fetched, user]);
};

export const useGetCourse = id => {
  const courses = useSelector(selectCourses);
  if (!id) {
    return null;
  }
  return courses.find(course => +course.id === +id);
};

export const useGetCourseDataSets = id => {
  const datasets = useSelector(selectDatasets);
  const dispatch = useDispatch();
  if (!datasets[id]) {
    dispatch(getDataSets(id));
  }
  return datasets[id];
};

export const useCreateStudentApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback(
    (id, data) => {
      const requestData = { ...data, course: id };
      return dispatch(createStudentApplication(requestData)).then(() => {
        dispatch(loadCourses());
        dispatch(loadApplications());
      });
    },
    [dispatch]
  );

  return request;
};

export const useCreateTAApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback(
    (id, data) => {
      const requestData = { ...data, course: id };
      return dispatch(createTAApplication(requestData)).then(() => {
        dispatch(loadCourses());
        dispatch(loadApplications());
      });
    },
    [dispatch]
  );

  return request;
};

export const useCreateTAOnboardingRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback(
    (id, data) => {
      const requestData = { ...data, application: id };
      return dispatch(createTAOnboarding(requestData)).then(() => {
        dispatch(loadCourses());
        dispatch(loadApplications());
      });
    },
    [dispatch]
  );

  return request;
};



export const useCreateMentorApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback(
    (id, data) => {
      const requestData = { ...data, course: id };
      return dispatch(createMentorApplication(requestData)).then(() => {
        dispatch(loadCourses());
        dispatch(loadApplications());
      });
    },
    [dispatch]
  );

  return request;
};

export const useUpdateTAApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback((applicationId, data) => dispatch(updateTAApplication(applicationId, data)), [dispatch]);

  return request;
};

export const useUpdateStudentApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback((applicationId, data) => dispatch(updateStudentApplication(applicationId, data)), [
    dispatch,
  ]);

  return request;
};

export const useUpdateMentorApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback((applicationId, data) => dispatch(updateMentorApplication(applicationId, data)), [
    dispatch,
  ]);

  return request;
};

export const useGetStudentApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback(applicationId => dispatch(getStudentApplication(applicationId)), [dispatch]);

  return request;
};

export const useGetStudentApplication = applicationId => {
  const [application, setApplication] = useState(null);
  const request = useGetStudentApplicationRequest();

  useEffect(() => {
    request(applicationId).then(response => {
      setApplication(response);
    });
  }, [request, applicationId]);

  return application;
};

export const useGetTAApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback(applicationId => dispatch(getTAApplication(applicationId)), [dispatch]);

  return request;
};

export const useGetTAApplication = applicationId => {
  const [application, setApplication] = useState(null);
  const request = useGetTAApplicationRequest();

  useEffect(() => {
    request(applicationId).then(response => {
      setApplication(response);
    });
  }, [request, applicationId]);

  return application;
};

export const useGetMentorApplicationRequest = () => {
  const dispatch = useDispatch();
  const request = useCallback(applicationId => dispatch(getMentorApplication(applicationId)), [dispatch]);

  return request;
};

export const useGetMentorApplication = applicationId => {
  const [application, setApplication] = useState(null);
  const request = useGetMentorApplicationRequest();

  useEffect(() => {
    request(applicationId).then(response => {
      setApplication(response);
    });
  }, [request, applicationId]);

  return application;
};

export const useValidCourseTypeInUrl = () => {
  const { type } = getUrlSearchParams();

  if (!applicationTypes[type]) {
    history.push('/');
    return false;
  }

  return true;
};

export const useWidthdrawApplicationRequest = () => {
  const dispatch = useDispatch();
  const callback = (id, type) => {
    if (type === applicationTypes.mentor) {
      dispatch(widthdrawMentorApplication(id));
    }
    if (type === applicationTypes.ta) {
      dispatch(widthdrawTAApplication(id));
    }
    if (type === applicationTypes.student) {
      dispatch(widthdrawStudentApplication(id));
    }
  };
  return callback;
};

export const useGetApplicationRequest = (id, type) => {
  const applicationsRequests = useSelector(selectApplications);
  const application = applicationsRequests.find(apl => {
    const applicationId = apl.mentor_application || apl.student_application || apl.ta_application;
    return +applicationId === +id && type === apl.application_type;
  });
  return application;
};

export const useGetPodRequest = () => {
  const dispatch = useDispatch();

  return useCallback(id => dispatch(getPod(id)), [dispatch]);
};

export const useGetPod = id => {
  const [pod, setPod] = useState(null);
  const getPod = useGetPodRequest();
  useEffect(() => {
    if (!pod && id) {
      getPod(id).then(response => {
        setPod(response.data);
      });
    }
  }, [pod, getPod, id]);
  return pod;
};

export const useGetPodWithStatus = id => {
  const [pod, setPod] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const getPod = useGetPodRequest();
  useEffect(() => {
    if (!pod && id) {
      getPod(id)
        .then(response => {
          setPod(response.data);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    } else {
      setIsLoaded(true);
    }
  }, [pod, getPod, id]);
  return { pod, isLoaded };
};

export const useCheckAccessToDashboard = () => {
  const { id } = useParams();
  const { type } = getUrlSearchParams();
  const applicationsModel = useSelector(selectApplicationsModel);

  if (!applicationsModel.isLoaded) {
    return;
  }

  const applicationsRequests = applicationsModel?.value?.results || [];
  const application = applicationsRequests.find(apl => {
    const applicationId = apl.mentor_application || apl.student_application || apl.ta_application;
    return +applicationId === +id && type === apl.application_type;
  });

  if (!application) {
    return history.push(applicationsRoutes.homepage);
  }

  const forbiddenStatuses = [applicationRequestStatuses.applied, applicationRequestStatuses.declined];
  if (forbiddenStatuses.includes(application.status)) {
    return history.push(applicationsRoutes.homepage);
  }
};

export const useCheckAccessToEdit = type => {
  const { applicationId: applicationIdInUrl } = useParams();
  const applicationsModel = useSelector(selectApplicationsModel);

  if (!applicationsModel.isLoaded) {
    return;
  }

  const applicationsRequests = applicationsModel?.value?.results || [];
  const application = applicationsRequests.find(apl => {
    const applicationId = apl.mentor_application || apl.student_application || apl.ta_application;
    return +applicationId === +applicationIdInUrl && type === apl.application_type;
  });

  if (!application) {
    return history.push(applicationsRoutes.homepage);
  }

  const status = application.status;
  const resolvedStatuses = [applicationRequestStatuses.applied, applicationRequestStatuses.declined];

  if (!resolvedStatuses.includes(status)) {
    return history.push(applicationsRoutes.homepage);
  }
};

export const useCreateOrUpdateCertificate = () => {
  const dispatch = useDispatch();
  return ({ user, pod, course, completedCourse, completedProject }) =>
    dispatch(
      createOrUpdateCertificate({
        user,
        pod,
        course,
        completed_course: completedCourse,
        completed_project: completedProject,
      })
    );
};

export const useCreateOrUpdateAttendance = () => {
  const dispatch = useDispatch();
  return (id, { attendanceId, day, percentage, project_percentage}) =>
    dispatch(
      createOrUpdateAttendance(id, {
        attendanceId,
        day,
        percentage,
        project_percentage
      })
    );
};


export const useCheckAccessToEditApplication = () => {};
