import { ReduxController } from '~/common/libs/ReduxController/ReduxController';
import { axios } from '~/app/axios/axios';
import { ApplicationsActionGroups, ApplicationsSliceNames } from '~/modules/applications/redux/applications.store';
import { setSnackbar } from '~/modules/app/redux/app.actions';
import { whoami } from '../../user/redux/user.actions';

export const loadCourses = (page = 1, page_size = 10) =>
  ReduxController.createAction({
    callApi: () => axios.get('/courses/', { params: { page, page_size } }),
    actionGroupName: ApplicationsActionGroups[ApplicationsSliceNames.courses],
  });

export const loadApplications = () =>
  ReduxController.createAction({
    callApi: () => axios.get('/courses/applications/'),
    actionGroupName: ApplicationsActionGroups[ApplicationsSliceNames.applications],
  });

export const getDataSets = id =>
  ReduxController.createAction({
    callApi: () => axios.get(`/courses/${id}/data-sets/`),
    afterSuccessCall: (response, dispatch) => {
      dispatch({
        type: ApplicationsActionGroups[ApplicationsSliceNames.datasets],
        payload: { id, response },
      });
    },
  });

export const clearCourses = () => ({
  type: ApplicationsActionGroups[ApplicationsSliceNames.courses] + '_CLEAR',
});

export const clearApplications = () => ({
  type: ApplicationsActionGroups[ApplicationsSliceNames.applications] + '_CLEAR',
});

export const createStudentApplication = data =>
  ReduxController.createAction({
    callApi: () => axios.post(`/courses/student-applications/`, data),
  });

export const updateStudentApplication = (applicationId, data) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/student-applications/${applicationId}/`, data),
  });

export const updateTAApplication = (applicationId, data) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/ta-applications/${applicationId}/`, data),
  });

export const createTAOnboarding = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/financial/ta-onboarding/', data),
    // afterSuccessCall: (_, dispatch) => {
    //   dispatch(whoami());
    // },
  });

export const getStudentApplication = applicationId =>
  ReduxController.createAction({
    callApi: () => axios.get(`/courses/student-applications/${applicationId}/`),
  });

export const getTAApplication = applicationId =>
  ReduxController.createAction({
    callApi: () => axios.get(`/courses/ta-applications/${applicationId}/`),
  });

export const getMentorApplication = applicationId =>
  ReduxController.createAction({
    callApi: () => axios.get(`/courses/mentor-applications/${applicationId}/`),
  });

export const createTAApplication = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/courses/ta-applications/', data),
  });

export const createMentorApplication = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/courses/mentor-applications/', data),
  });

export const updateMentorApplication = (applicationId, data) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/mentor-applications/${applicationId}/`, data),
  });

export const widthdrawStudentApplication = applicationId =>
  ReduxController.createAction({
    callApi: () => axios.delete(`/courses/student-applications/${applicationId}/`),
    afterSuccessCall: (_, dispatch) => {
      dispatch(loadApplications());
      dispatch(loadCourses());
    },
    afterFailCall: (_, dispatch) => {
      dispatch(setSnackbar(`You can't withdraw now`));
    },
  });

export const widthdrawTAApplication = applicationId =>
  ReduxController.createAction({
    callApi: () => axios.delete(`/courses/ta-applications/${applicationId}/`),
    afterSuccessCall: (_, dispatch) => {
      dispatch(loadApplications());
      dispatch(loadCourses());
    },
  });

export const widthdrawMentorApplication = applicationId =>
  ReduxController.createAction({
    callApi: () => axios.delete(`/courses/mentor-applications/${applicationId}/`),
    afterSuccessCall: (_, dispatch) => {
      dispatch(loadApplications());
      dispatch(loadCourses());
    },
  });

export const getPod = id =>
  ReduxController.createAction({
    callApi: () => axios.get(`/courses/pods/${id}/`),
  });

export const selfFunded = (id, reason) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/student-applications/${id}/self-funded/`, { self_funded_reasons: reason }),
  });

export const hardship = (id, reason) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/student-applications/${id}/hardship/`, { hardship_reasons: reason }),
  });

export const waiveFee = (id, reason) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/student-applications/${id}/waive-fee/`, { waive_fee_reasons: reason }),
  });

export const createOrUpdateCertificate = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/certificates/', data),
  });

export const createOrUpdateAttendance = (id, data) =>
  ReduxController.createAction({
    callApi: () => axios.put(`/courses/attendance/${id}/`, data),
  });
