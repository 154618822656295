import { Block } from '~/common/components/Block/Block';
import { Form } from '~/common/components/Form/Form';
import { Controller, useForm } from 'react-hook-form';
import { useRef, useState } from "react";
import { Button } from '~/common/components/Button/Button';
import { Link } from '~/common/components/Link/Link';
import { useEffect } from 'react';
import { Select } from '~/common/components/Select/Select';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { useGetStudentApplication, useSelfFundedRequest } from '~/modules/applications/models/application.hooks';
import { useOnError } from '~/modules/app/models/app.hooks';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { baselinePrice, setBaselinePrice, calculateAcademicDiscount, calculateCOLAAdjustment, calculateSelfFundedDiscount, calculateProcessingFee} from '~/modules/applications/models/application.model';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
import {
  funding,
  fundingOptions
} from '~/modules/applications/models/application.model';

dayjs.extend(utc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const formNames = {
    external_funding : 'external_funding',
    self_funding_explanation: 'self_funding_explanation',
    agree: 'agree'
}

export const StudentPaySelfFundedDiscount = ({ application, previousPage, nextPage, defaultValues}) => {
  const { register, handleSubmit, trigger, setValue, setError, formState: { errors }, watch, control } = useForm({ mode: 'onBlur', defaultValues });
  const selfFunded = useSelfFundedRequest();
  const setErrors = setErrorsFunction(setError);
  const onError = useOnError(setErrors);
  // const fundValue = watch(formNames.external_funding);
  const [fundValue, setFundValue] = useState(null);
  const studentApplication = useGetStudentApplication(application.student_application)?.data;

  const course_price = application.course_price;
  // Set baseline price as course price 
  setBaselinePrice(course_price);

  useEffect(() => {
    register({ name: formNames.external_funding});
    register({ name: formNames.self_funding_explanation});
  }, [register]);

  const dispatch = useDispatch();

  const isFunded = !(fundValue === funding.self_funded || fundValue === funding.yes_denied)

  const isAcademia = studentApplication && !!(studentApplication.is_industry ? 0 : 1)
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const residingCountry = studentApplication && studentApplication.residing_country_during_course
  const selfFundedDiscount = studentApplication && calculateSelfFundedDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const processingFee = studentApplication && parseFloat(calculateProcessingFee(studentApplication.cola))
  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount + processingFee).toFixed(2);

  const formRef = useRef();

  const onSelfFunded = data => {
    selfFunded(application.student_application, data[formNames.self_funding_explanation])
      .then(() => {
      })
      .catch(onError);
  };

  const onContinue = (data) => {
      onSelfFunded(data);
      nextPage()
      scrollToTop()
  }

  const onSubmit = data => {
    const locationUrl = window.location.href;
    if (!isFunded) {
      onSelfFunded(data);
    }
    const price = Math.floor(recommendedPayment * 100);
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <>
      <Block title="Enrollment: Funding" className="student-payment">
        <Form ref={formRef}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
            <Form.Row>
              Most universities and research groups directly pay for these registration fees from grants and/or educational funds. While it may not always be clear that these programs exist, and students may not feel entitled to ask, these organizations are familiar with such requests and are prepared to fund great training programs as they make both you and your organization stronger.
              <br></br><br></br>
              We encourage you to ask your organization for help in funding your training program, and we have provided an example message that you could send to your supervisor, department head, or manager to start the conversation: &nbsp;
            <Link
              to="https://github.com/NeuromatchAcademy/precourse/blob/main/enrollmentforms/funding_template.md"
              isCrossDomain
              newTab
            >
              example message
            </Link>
          </Form.Row>
          <Form.Row>
            If you are unable to secure reimbursement after exploring all available options, we will reduce your course fee by 50%. 
          </Form.Row>
          <Form.Row>
            <Controller
                name={formNames.external_funding}
                control={control}
                rules={{ required: true }}
                render={({ onChange, value, name, ref }) => (
                    <Select
                        inputRef={ref}
                        label="Have you asked your manager, PI, and/or department about funding?"
                        options={fundingOptions}
                        value={fundingOptions.find(c => c.value === value)}
                        onChange={val => {onChange(val.value); setFundValue(val.value)}}
                    />
                )}
              />
              {errors[formNames.external_funding] && errors[formNames.external_funding]?.type === "required" ? (
                <div className="error">
                    {"Must select one option"}
                </div>
              ) : null}
           </Form.Row>
          {(fundValue === funding.self_funded || fundValue === funding.yes_denied)  && (<Form.Row>
          <Textarea
            name={formNames.self_funding_explanation}
             rows={4}
            innerRef={register({ required: "Required field",
                    minLength: {
                        value: 200,
                        message: "Mininimum length is 200 characters"
            }}
            )}
            label="If you are not able to get complete funding, please explain your situation in more detail"
            error={errors[formNames.self_funding_explanation]?.message}
          />
        </Form.Row>)}
          <Form.Row className="student-application-status__discount-table">
            <center>
            <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
              <tbody>
              <tr>
                <td>Baseline Price</td>
                <td className="alignright">  $ {baselinePrice}</td>
              </tr>
              {isAcademia && (<tr>
                <td>Academic Discount</td>
                <td className="alignright">- $ {academicDiscount}</td>
              </tr>)}
              <tr>
                <td>Cost of Living Adjustment ({residingCountry})</td>
                <td className="alignright">- $ {colaAdjustment}</td>
              </tr>
              {(fundValue === funding.self_funded || fundValue === funding.yes_denied)  && (<tr>
                <td>Self-funded discount</td>
                <td className="alignright">- $ {selfFundedDiscount}</td>
              </tr>)}
              <tr>
                <td>Non-Refundable Processing Fee</td>
                <td className="alignright">  $ {processingFee}</td>
              </tr>
              <tr className="total">
                <td className="alignright" width="80%"><b>Your Total</b></td>
                <td className="alignright"><b>$ {recommendedPayment} (USD)</b></td>
              </tr>
              </tbody>
            </table>
              </center>
            </Form.Row>
          <Form.Row className="student-application-status__pay-button">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row>
          {(fundValue === funding.self_funded || fundValue === funding.yes_denied) && (
            <>
              <br></br>
              <br></br>
              <Form.Row>
                <h5>Hardship Discount</h5>
              </Form.Row>
              <Form.Row>
                In exceptional circumstances, individuals who cannot afford the reduced course fee outlined above may apply for a <i>Hardship Discount</i>. These requests are for individuals who are experiencing <i>significant</i> financial hardship in their local context and genuinely cannot afford the course fee above (which has already been adjusted for cost of living in your country and includes a self-funded discount). You will need to submit a statement detailing your financial situation and explaining why you are unable to pay the course fee.
              </Form.Row>
              <Form.Row>
                Please note that Hardship Discounts are limited and intended for those in the most need. If you are not experiencing financial hardship, we ask that you do not apply. Our goal is to ensure that financial barriers do not prevent access for those who truly need it.
              </Form.Row>
              <Form.Row className="student-application-status__buttons">
                <Button className="student-application-status__buttons" type="submit" onClick={handleSubmit(onContinue)}>
                  Apply for Hardship Discount
                </Button>
              </Form.Row>
            </>
          )}
        </Form>
      </Block>
    </>
  );
};
