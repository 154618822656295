import { Block } from '~/common/components/Block/Block';
import { Form } from '~/common/components/Form/Form';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '~/common/components/Button/Button';
import { useEffect, useState, useRef } from 'react';
import { Select } from '~/common/components/Select/Select';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { useGetStudentApplication, useHardshipRequest } from '~/modules/applications/models/application.hooks';
import { useOnError } from '~/modules/app/models/app.hooks';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { baselinePrice, setBaselinePrice, calculateProcessingFee, calculateAcademicDiscount, calculateCOLAAdjustment, calculateSelfFundedDiscount, calculateAffordabilityDiscount, calculatePayment } from '~/modules/applications/models/application.model';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
import {
  yesOrNoPairings,
  yesOrNo
} from '~/modules/applications/models/application.model';

dayjs.extend(utc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const formNames = {
    affordability_discount : 'affordability_discount',
    hardship_explanation: 'hardship_explanation',
}

export const StudentPayAffordabilityDiscount = ({ application, previousPage, nextPage, defaultValues}) => {

  const { register, handleSubmit, errors, setError, watch, control } = useForm({ defaultValues });
  const setErrors = setErrorsFunction(setError);
  const onError = useOnError(setErrors);
  const hardship = useHardshipRequest();
  const studentApplication = useGetStudentApplication(application.student_application)?.data;
  const course_price = application.course_price;
  // Set baseline price as course price 
  setBaselinePrice(course_price);

  // const affordabilityValue = watch(formNames.affordability_discount);
  const [affordabilityValue, setAffordabilityValue] = useState(null);

  useEffect(() => {
    register({ name: formNames.affordability_discount });
    register({ name: formNames.hardship_explanation });
  }, [register]);


  const onHardship = data => {
    hardship(application.student_application, data[formNames.hardship_explanation])
      .then(() => {
      })
      .catch(onError);
  };
  
  const ref = useRef();

  const dispatch = useDispatch();

  const isFunded = false

  const isAcademia = studentApplication && !!(studentApplication.is_industry ? 0 : 1)
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const residingCountry = studentApplication && studentApplication.residing_country_during_course
  const selfFundedDiscount = studentApplication && calculateSelfFundedDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const processingFee = studentApplication && parseFloat(calculateProcessingFee(studentApplication.cola))
  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount + processingFee).toFixed(2);

  const onContinue = (data) => {
    onHardship(data);
    nextPage();
    scrollToTop();
  }

  const onSubmit = data => {
    const locationUrl = window.location.href;

    const price = Math.floor(recommendedPayment * 100);
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <>
      <Block title="Enrollment: Hardship Discount" className="student-payment">
        <Form ref={ref}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
          <Form.Row>
            In exceptional circumstances, individuals may apply for a <i>Hardship Discount</i>. These requests are for individuals who are experiencing <i>significant</i> financial hardship and genuinely cannot afford the course fee which has been adjusted for cost of living and includes a self-funded discount. 
          </Form.Row>
          <Form.Row>
            Please note that these discounts are limited and intended for those in the most need. <b>If you are not experiencing financial hardship, we ask that you do not apply</b>. Our goal is to ensure that financial barriers do not prevent access for those who truly need it.
          </Form.Row>
          <Form.Row>
            <Controller
                name={formNames.affordability_discount}
                control={control}
                rules={{ required: true }}
                render={({ onChange, value, name, ref }) => (
                    <Select
                        inputRef={ref}
                        label="I am experiencing financial hardship and genuinely cannot afford the reduced course fee:"
                        options={yesOrNo}
                        value={yesOrNo.find(c => c.value === value)}
                        onChange={val => {onChange(val.value); setAffordabilityValue(val.value)}}
                    />
                )}
              />
              {errors[formNames.affordability_discount] && errors[formNames.affordability_discount]?.type === "required" ? (
                <div className="error">
                    {"Must select one option"}
                </div>
              ) : null}
           </Form.Row>
          {affordabilityValue === yesOrNoPairings.no && (
            <>
              <Form.Row className="student-application-status__discount-table">
                <center>
                  <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
                    <tbody>
                    <tr>
                      <td>Baseline Price</td>
                      <td className="alignright">  $ {baselinePrice}</td>
                    </tr>
                    {isAcademia && (<tr>
                      <td>Academic Discount</td>
                      <td className="alignright">- $ {academicDiscount}</td>
                    </tr>)}
                    <tr>
                      <td>Cost of Living Adjustment ({residingCountry})</td>
                      <td className="alignright">- $ {colaAdjustment}</td>
                    </tr>
                    {!isFunded && (<tr>
                      <td>Self-funded discount</td>
                      <td className="alignright">- $ {selfFundedDiscount}</td>
                    </tr>)}
                    <tr>
                      <td>Non-Refundable Processing Fee</td>
                      <td className="alignright">  $ {processingFee}</td>
                    </tr>
                    <tr className="total">
                      <td className="alignright" width="80%"><b>Your Total</b></td>
                      <td className="alignright"><b>$ {recommendedPayment} (USD)</b></td>
                    </tr>
                    </tbody>
                  </table>
                </center>
              </Form.Row>
              <Form.Row className="student-application-status__pay-button">
                <Button
                  type="submit"
                  className="student-application-status__pay-button"
                  onClick={handleSubmit(onSubmit)}>
                    Pay now
                </Button>
              </Form.Row>
            </>
          )}
          {affordabilityValue === yesOrNoPairings.yes && (
            <>
              <Form.Row>
                To apply, please submit a short statement detailing your financial situation and explaining why you are unable to pay the course fee. Please note that your course fee has already been cost-adjusted for your country of residence during the course and included a discount for self-funding. 
              </Form.Row>
              <Form.Row>
                <Textarea
                  name={formNames.hardship_explanation}
                  rows={4}
                  innerRef={register({
                    required: "Required field",
                    minLength: {
                      value: 200,
                      message: "Mininimum length is 200 characters"
                    }
                  })}
                  // label="If you are not able to get complete funding, please explain your situation in more detail"
                  error={errors[formNames.hardship_explanation]?.message}
                />
              </Form.Row>
              <Form.Row className="student-application-status__buttons">
                <Button className="student-application-status__buttons" type="submit" onClick={handleSubmit(onContinue)}>
                  Apply for Hardship Discount
                </Button>
              </Form.Row>
            </>
          )}
        </Form>
      </Block>
    </>
  );
};


export const StudentPayAffordabilityDiscount2 = ({ application, previousPage, nextPage, defaultValues}) => {

  const { register, handleSubmit, errors, watch, control } = useForm({ defaultValues });
  const studentApplication = useGetStudentApplication(application.student_application)?.data;
  const course_price = application.course_price;
  // Set baseline price as course price 
  setBaselinePrice(course_price);

  const affordabilityValue = watch(formNames.affordability_discount);

  useEffect(() => {
    register({ name: formNames.affordability_discount });
  }, [register]);

  const dispatch = useDispatch();

  const isFunded = false

  const isAcademia = studentApplication && !!(studentApplication.is_industry ? 0 : 1)
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const residingCountry = studentApplication && studentApplication.residing_country_during_course
  const selfFundedDiscount = studentApplication && calculateSelfFundedDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const affordabilityDiscount = studentApplication && calculateAffordabilityDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const processingFee = studentApplication && parseFloat(calculateProcessingFee(studentApplication.cola))
  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount - affordabilityDiscount + processingFee).toFixed(2);

  const onSubmit = data => {
    const locationUrl = window.location.href;

    const price = Math.floor(recommendedPayment * 100);
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <>
      <Block title="Enrollment: Hardship Discount" className="student-payment">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
          <Form.Row>
            Your course fee below includes a cost of living adjustment based on your country of residence during the course, a self-funded discount, and a financial hardship discount. 
          </Form.Row>
          <Form.Row className="student-application-status__discount-checklist">
            <Form.Row>&#10004; Apply my institution discount</Form.Row>
            <Form.Row>&#10004; Apply my cost of living adjustment</Form.Row>
            <Form.Row>&#10004; Apply self-funded discount</Form.Row>
            <Form.Row>&#10004; Apply financial hardship discount</Form.Row>
          </Form.Row>
          <br></br>
          <Form.Row className="student-application-status__discount-table">
            <center>
            <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
              <tbody>
              <tr>
                <td>Baseline Price</td>
                <td className="alignright">  $ {baselinePrice}</td>
              </tr>
              {isAcademia && (<tr>
                <td>Academic Discount</td>
                <td className="alignright">- $ {academicDiscount}</td>
              </tr>)}
              <tr>
                <td>Cost of Living Adjustment ({residingCountry})</td>
                <td className="alignright">- $ {colaAdjustment}</td>
              </tr>
              {!isFunded && (<tr>
                <td>Self-funded discount</td>
                <td className="alignright">- $ {selfFundedDiscount}</td>
              </tr>)}
              <tr>
                <td>Hardship discount (50% of fee before, again not including non-refundable fee)</td>
                <td className="alignright">- $ {affordabilityDiscount}</td>
              </tr>
              <tr>
                <td>Non-Refundable Processing Fee</td>
                <td className="alignright">  $ {processingFee}</td>
              </tr>
              <tr className="total">
                <td className="alignright" width="80%"><b>Your Total</b></td>
                <td className="alignright"><b>$ {recommendedPayment} (USD)</b></td>
              </tr>
              </tbody>
            </table>
              </center>
            </Form.Row>
          <Form.Row className="student-application-status__pay-button">
            <Button
              type="submit"
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row>
          {studentApplication?.cola <= 0.3 && (
            <>
              <Form.Row> 
                Our course fees are essential to sustaining our programs and without them Neuromatch would not be able to operate. We therefore ask that you pay the above fee, which includes a cost of living adjustment based on your country of residence during the course, a self-funded discount, and an additional financial hardship discount. 
              </Form.Row>
              <Form.Row className="student-application-status__buttons">
                <Button className="student-application-status__buttons" onClick={() => {nextPage(); scrollToTop();}}>
                  I cannot afford to this price 
                </Button>
              </Form.Row>
            </>
          )}
        </Form>
      </Block>
    </>
  );
};
